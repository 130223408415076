var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"createProductForm"},[_c('b-form',{staticClass:"mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.createProduct.apply(null, arguments)}}},[_c('b-container',[_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Código uso interno","label-for":"product-code"}},[_c('validation-provider',{attrs:{"name":"Código uso interno","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"product-code","state":errors.length > 0 ? false : null,"name":"product-code"},model:{value:(_vm.product.code),callback:function ($$v) {_vm.$set(_vm.product, "code", $$v)},expression:"product.code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Código cabys","label-for":"product-cabys-code"}},[_c('validation-provider',{attrs:{"name":"Código_cabys","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('v-select',{attrs:{"label":"descripcion","options":_vm.options,"filterable":false},on:{"search":_vm.fetchOptions},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var codigo = ref.codigo;
var descripcion = ref.descripcion;
return [_c('p',{staticStyle:{"margin":"0"}},[_vm._v(" "+_vm._s(codigo)+" - "+_vm._s(descripcion)+" ")])]}},{key:"selected-option",fn:function(ref){
var codigo = ref.codigo;
var descripcion = ref.descripcion;
return [_c('p',{staticStyle:{"margin":"0"}},[_vm._v(" "+_vm._s(codigo)+" - "+_vm._s(descripcion)+" ")])]}}],null,true),model:{value:(_vm.product.cabys),callback:function ($$v) {_vm.$set(_vm.product, "cabys", $$v)},expression:"product.cabys"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-form-group',{attrs:{"label":"Categoría","label-for":"product-category"}},[_c('validation-provider',{attrs:{"name":"Categoría","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('b-form-select',{attrs:{"id":"product-category","options":_vm.productCategories,"value-field":"id","text-field":"name"},model:{value:(_vm.product.category),callback:function ($$v) {_vm.$set(_vm.product, "category", $$v)},expression:"product.category"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-form-group',{attrs:{"label":"Unidad","label-for":"product-unit"}},[_c('validation-provider',{attrs:{"name":"Unidad","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('b-form-select',{attrs:{"id":"product-unit","options":_vm.units,"value-field":"id","text-field":"description"},model:{value:(_vm.product.unit),callback:function ($$v) {_vm.$set(_vm.product, "unit", $$v)},expression:"product.unit"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-form-group',{attrs:{"label":"Nombre","label-for":"product-name"}},[_c('validation-provider',{attrs:{"name":"Nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"product-name","state":errors.length > 0 ? false : null,"name":"product-name"},model:{value:(_vm.product.name),callback:function ($$v) {_vm.$set(_vm.product, "name", $$v)},expression:"product.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Descripción","label-for":"product-description"}},[_c('validation-provider',{attrs:{"name":"Descripción","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"product-description","state":errors.length > 0 ? false : null,"name":"product-description","multiline":""},model:{value:(_vm.product.description),callback:function ($$v) {_vm.$set(_vm.product, "description", $$v)},expression:"product.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Moneda","label-for":"product-currency"}},[_c('validation-provider',{attrs:{"name":"Moneda","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('b-form-select',{attrs:{"id":"product-currency","options":_vm.currencies,"value-field":"id","text-field":"name"},model:{value:(_vm.product.currency),callback:function ($$v) {_vm.$set(_vm.product, "currency", $$v)},expression:"product.currency"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-form-group',{attrs:{"label":"Precio","label-for":"product-price"}},[_c('validation-provider',{attrs:{"name":"Precio","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"product-price","state":errors.length > 0 ? false : null,"name":"product-price"},model:{value:(_vm.product.price),callback:function ($$v) {_vm.$set(_vm.product, "price", $$v)},expression:"product.price"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',[_c('h4',[_vm._v("Impuestos")]),_c('ul',{staticClass:"list-group"},_vm._l((_vm.product.taxes),function(t,index){return _c('li',{key:index,staticClass:"list-group-item list-group-item"},[_vm._v(" "+_vm._s(t.tax_code.name)+" - "+_vm._s(t.tax_type.name)+" "),_c('feather-icon',{staticClass:"float-right text-danger cursor-pointer",attrs:{"icon":"XCircleIcon","size":"1.2x"},on:{"click":function($event){return _vm.deleteTax(index)}}})],1)}),0),_c('b-form-group',{attrs:{"label":"Tipo de impuesto","label-for":"product-tax_code"}},[_c('validation-provider',{attrs:{"name":"Tipo de impuesto"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('b-form-select',{attrs:{"id":"product-tax_code","options":_vm.taxCodes,"value-field":"id","text-field":"name"},model:{value:(_vm.tax.tax_code),callback:function ($$v) {_vm.$set(_vm.tax, "tax_code", $$v)},expression:"tax.tax_code"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-form-group',{attrs:{"label":"Tarifa impuesto","label-for":"product-tax_type"}},[_c('validation-provider',{attrs:{"name":"Tarifa impuesto"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('b-form-select',{attrs:{"id":"product-tax_type","options":_vm.taxTypes,"value-field":"id","text-field":"name"},model:{value:(_vm.tax.tax_type),callback:function ($$v) {_vm.$set(_vm.tax, "tax_type", $$v)},expression:"tax.tax_type"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-button',{staticClass:"px-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.addTax()}}},[_c('feather-icon',{attrs:{"size":"1.2x","icon":"PlusIcon"}})],1)],1)],1),_c('b-row',[_c('b-col',[_c('div',{staticClass:"d-flex justify-content-center align-items-center py-5"},[_c('b-button',{attrs:{"type":"submit","variant":"primary"}},[_vm._v("Guardar")])],1)])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }